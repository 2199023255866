































import { InputSetups } from '@/mixins/input-setups'
import { PercentCondition } from "../PercentCondition/PercentCondition";

import ToggleButton from 'piramis-base-components/src/components/ToggleButton.vue'

import { Component, Mixins, VModel } from 'vue-property-decorator'

@Component({
  components: {
    ToggleButton
  }
})
export default class PercentConditionView extends Mixins<InputSetups>(InputSetups) {
  @VModel() model!: PercentCondition

  get mode() {
    return this.model.mode === "Greater"
  }

  set mode(state: boolean) {
    this.model.mode = state ? "Greater" : "Less"
  }
}
